import React from 'react';

const ServicePage: React.FC = () => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">Service</h1>
      <p>Manage services and subscriptions related to your account.</p>
      {/* Add service-related content */}
    </div>
  );
};

export default ServicePage;
