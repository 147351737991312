// import React from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';

// type HeaderProps = {
//   logoSrc: string;
//   isAuthenticated: boolean;
//   onLogout: () => void;
// };

// const Header: React.FC<HeaderProps> = ({ logoSrc, isAuthenticated, onLogout }) => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     onLogout();
//     navigate('/login');
//   };

//   return (
//     <header className="flex flex-wrap gap-5 justify-between px-8 w-full text-base text-center bg-white bg-opacity-90 shadow-[0px_0px_4px_rgba(14,19,24,0.07)] max-md:px-5 max-md:max-w-full">
//       <nav className="flex flex-wrap gap-10 items-center text-gray-600 max-md:max-w-full">
//         <img
//           loading="lazy"
//           src={logoSrc}
//           alt="Company Logo"
//           className="object-contain shrink-0 self-stretch max-w-full rounded aspect-[2.92] w-[210px]"
//         />

//         <NavLink
//           to="/"
//           className={({ isActive }) =>
//             isActive ? 'self-stretch my-auto basis-auto text-blue-600' : 'self-stretch my-auto basis-auto'
//           }
//         >
//           Dashboard
//         </NavLink>

//         <NavLink
//           to="/"
//           className={({ isActive }) =>
//             isActive ? 'self-stretch my-auto basis-auto text-blue-600' : 'self-stretch my-auto basis-auto'
//           }
//         >
//           Resume Matching
//         </NavLink>


//         <div className="flex gap-10 self-stretch my-auto">
//           <button className="text-gray-600">Tools & API</button>
//           <button className="text-gray-600">Pricing</button>
//         </div>
//       </nav>

//       <div className="flex gap-8 my-auto">
//         {isAuthenticated ? (
//           <div className="flex items-center gap-4">
//             <div className="relative">
//               <img
//                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b03d766015fd0cd61d52887ca06f32e5881c90a59c5128fcc8243dac8af519f?placeholderIfAbsent=true&apiKey=6e0d7b98af9d40898559255c7d27a150"
//                 alt="Profile"
//                 className="w-8 h-8 rounded-full cursor-pointer"
//                 onClick={() => {
//                   // Optional: Show a dropdown for profile settings
//                 }}
//               />
//             </div>
//             <button
//               onClick={handleLogout}
//               className="text-gray-600 hover:text-red-600"
//             >
//               Logout
//             </button>
//           </div>
//         ) : (
//           <>
//             <NavLink
//               to="/login"
//               className={({ isActive }) =>
//                 isActive ? 'my-auto text-gray-600 border-b-2 border-blue-600' : 'my-auto text-gray-600'
//               }
//             >
//               Log in
//             </NavLink>
//             <NavLink
//               to="/signup"
//               className={({ isActive }) =>
//                 isActive
//                   ? 'px-6 py-3 bg-gray-100 rounded-full text-zinc-700 max-md:px-5 shadow-lg'
//                   : 'px-6 py-3 bg-gray-100 rounded-full text-zinc-700 max-md:px-5 shadow-none'
//               }
//             >
//               Sign up
//             </NavLink>
//           </>
//         )}
//       </div>
//     </header>
//   );
// };

// export default Header;




import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

type HeaderProps = {
  logoSrc: string;
  isAuthenticated: boolean;
  onLogout: () => void;
};

const Header: React.FC<HeaderProps> = ({ logoSrc, isAuthenticated, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <header className="flex flex-wrap gap-5 justify-between px-8 w-full text-base text-center bg-white bg-opacity-90 shadow-[0px_0px_4px_rgba(14,19,24,0.07)] max-md:px-5 max-md:max-w-full">
      <nav className="flex flex-wrap gap-10 items-center text-gray-600 max-md:max-w-full">
        <img
          loading="lazy"
          src={logoSrc}
          alt="Company Logo"
          className="object-contain shrink-0 self-stretch max-w-full rounded aspect-[2.92] w-[210px]"
        />

        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            isActive ? 'self-stretch my-auto basis-auto text-blue-600' : 'self-stretch my-auto basis-auto'
          }
        >
          Dashboard
        </NavLink>

        {/* Updated Resume Matching Route */}
        <NavLink
          to="/resume-matching"
          className={({ isActive }) =>
            isActive ? 'self-stretch my-auto basis-auto text-blue-600' : 'self-stretch my-auto basis-auto'
          }
        >
          Resume Matching
        </NavLink>

        <div className="flex gap-10 self-stretch my-auto">
          <button className="text-gray-600">Tools & API</button>
          <button className="text-gray-600">Pricing</button>
        </div>
      </nav>

      <div className="flex gap-8 my-auto">
        {isAuthenticated ? (
          <div className="flex items-center gap-4">
            <div className="relative">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b03d766015fd0cd61d52887ca06f32e5881c90a59c5128fcc8243dac8af519f?placeholderIfAbsent=true&apiKey=6e0d7b98af9d40898559255c7d27a150"
                alt="Profile"
                className="w-8 h-8 rounded-full cursor-pointer"
                onClick={() => {
                  // Optional: Show a dropdown for profile settings
                }}
              />
            </div>
            <button
              onClick={handleLogout}
              className="text-gray-600 hover:text-red-600"
            >
              Logout
            </button>
          </div>
        ) : (
          <>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? 'my-auto text-gray-600 border-b-2 border-blue-600' : 'my-auto text-gray-600'
              }
            >
              Log in
            </NavLink>
            <NavLink
              to="/signup"
              className={({ isActive }) =>
                isActive
                  ? 'px-6 py-3 bg-gray-100 rounded-full text-zinc-700 max-md:px-5 shadow-lg'
                  : 'px-6 py-3 bg-gray-100 rounded-full text-zinc-700 max-md:px-5 shadow-none'
              }
            >
              Sign up
            </NavLink>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
