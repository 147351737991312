import React from 'react';
import { FaArrowUp, FaArrowDown, FaDownload } from 'react-icons/fa';

interface Applicant {
  name: string;
  source: string;
  experience: string;
  status: string;
}

interface ApplicationTableProps {
  applicants: Applicant[];
}

const ApplicationTable: React.FC<ApplicationTableProps> = ({ applicants }) => {
  return (
    <div className="w-full mt-10 bg-white shadow-md rounded-lg overflow-hidden">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Name
            </th>
            <th className="px-5 py-3 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Source
            </th>
            <th className="px-5 py-3 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Experience
            </th>
            <th className="px-5 py-3 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Range
            </th>
            <th className="px-5 py-3 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Download
            </th>
            <th className="px-5 py-3 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {applicants.map((applicant, index) => (
            <tr key={index} className="border-b">
              <td className="px-5 py-5 text-sm">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="https://via.placeholder.com/40"
                      alt={applicant.name}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap font-semibold">{applicant.name}</p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 text-center text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{applicant.source}</p>
              </td>
              <td className="px-5 py-5 text-center text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{applicant.experience}</p>
              </td>
              <td className="px-5 py-5 text-center text-sm">
                {index % 2 === 0 ? (
                  <FaArrowUp className="text-green-500" />
                ) : (
                  <FaArrowDown className="text-red-500" />
                )}
              </td>
              <td className="px-5 py-5 text-center text-sm">
                <FaDownload className="text-black cursor-pointer" />
              </td>
              <td className="px-5 py-5 text-center text-sm">
                <span
                  className={`relative inline-block px-3 py-1 font-semibold leading-tight ${
                    applicant.status === 'Shortlisted'
                      ? 'text-green-800 bg-green-200'
                      : applicant.status === 'Rejected'
                      ? 'text-red-800 bg-red-200'
                      : 'text-yellow-800 bg-yellow-200'
                  } rounded-full`}
                >
                  {applicant.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApplicationTable;
