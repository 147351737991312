import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<any[]>([]);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [applications, setApplications] = useState<any[]>([]);
  const [selectedProfileId, setSelectedProfileId] = useState<number | null>(1);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedProfileId) {
          const statsResponse = await axios.get(`http://10.0.0.220:8009/stats?profile_id=${selectedProfileId}`);
          setStats(statsResponse.data);

          const meetingsResponse = await axios.get(`http://10.0.0.220:8009/meetings?profile_id=${selectedProfileId}`);
          setMeetings(meetingsResponse.data);

          const applicationsResponse = await axios.get(`http://10.0.0.220:8009/applicants?profile_id=${selectedProfileId}`);
          setApplications(applicationsResponse.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };
    fetchData();
  }, [selectedProfileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout
      stats={stats}
      meetings={meetings}
      applications={applications}
      onSelectProfile={(profileId: number) => setSelectedProfileId(profileId)}
      selectedProfileId={selectedProfileId} // Pass selectedProfileId to Layout
    >
      {/* Render main content */}
    </Layout>
  );
};

export default Dashboard;
