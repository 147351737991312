import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="w-full py-3 mt-4 text-2xl text-white bg-pink-400 rounded hover:bg-pink-600 border-3 border-black"
    >
      {text}
    </button>
  );
};

export default Button;
