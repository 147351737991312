import React from 'react';
import SidebarItem from './SidebarItem';
import { NavLink } from 'react-router-dom';

// Define the paths to your images
// import HomeIcon from '/Users/xs334-amakum/Desktop/IDP_Solution/idp/src/assets/images/home.png';
// import JobIcon from '/Users/xs334-amakum/Desktop/IDP_Solution/idp/src/assets/images/job.png';
// import HistoryIcon from '/Users/xs334-amakum/Desktop/IDP_Solution/idp/src/assets/images/history.png';
// import ServiceIcon from '/Users/xs334-amakum/Desktop/IDP_Solution/idp/src/assets/images/service.png';

import HomeIcon from '../assets/images/home.png';
import JobIcon from '../assets/images/job.png';
import HistoryIcon from '../assets/images/history.png';
import ServiceIcon from '../assets/images/service.png';

const sidebarItems = [
  { icon: HomeIcon, label: "Home", path: "/dashboard", isActive: true },
  { icon: JobIcon, label: "Post a Job", path: "/post-job" },
  { icon: HistoryIcon, label: "History", path: "/history" },
  { icon: ServiceIcon, label: "Service", path: "/service" },
];

const Sidebar: React.FC = () => {
  return (
    <aside className="flex flex-col w-[22%] max-md:ml-0 max-md:w-full">
      <nav className="flex flex-col items-start pt-28 pr-14 pb-96 pl-7 mx-auto w-full text-base rounded-3xl bg-zinc-50">
        <img loading="lazy" src="/path-to-your-logo.png" alt="Logo" className="object-contain self-stretch w-full aspect-[3.66]" />
        {sidebarItems.map((item, index) => (
          <NavLink
            to={item.path}
            className={({ isActive }) => isActive ? 'self-stretch my-auto basis-auto text-blue-600' : 'self-stretch my-auto basis-auto'}
            key={index}
          >
            <SidebarItem icon={item.icon} label={item.label} isActive={item.isActive} path={item.path}/>
          </NavLink>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
