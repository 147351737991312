// import React from 'react';

// interface BadgeProps {
//   text?: string;
//   count?: number;
// }

// interface SidebarItemProps {
//   icon: string; // This will be the path to the image now
//   label: string;
//   isActive?: boolean;
//   hasSubmenu?: boolean;
//   badge?: BadgeProps;
// }

// const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, isActive, hasSubmenu, badge }) => {
//   const baseClasses = "flex gap-4 px-4 py-3.5 mt-7 whitespace-nowrap text-4xl";
//   const activeClasses = isActive ? "text-black bg-zinc-100 rounded-[30px]" : "text-neutral-500";
//   const classes = `${baseClasses} ${activeClasses}`;

//   return (
//     <div className={classes}>
//       {/* Load icon from the local path */}
//       <img loading="lazy" src={icon} alt={label} className="object-contain shrink-0 w-6 aspect-square" />
//       <div>{label}</div>
//       {badge && (
//         <div className="flex gap-2 ml-auto">
//           {badge.text && (
//             <div className="px-2 py-1 text-white bg-blue-400 rounded-md">{badge.text}</div>
//           )}
//           {badge.count && (
//             <div className="px-2 w-6 h-6 text-black bg-emerald-200 rounded-md border border-emerald-200 border-solid">
//               {badge.count}
//             </div>
//           )}
//         </div>
//       )}
//       {hasSubmenu && (
//         <img loading="lazy" src="/path-to-submenu-icon.png" alt="" className="object-contain shrink-0 w-6 aspect-square ml-auto" />
//       )}
//     </div>
//   );
// };

// export default SidebarItem;




import React from 'react';
import { NavLink } from 'react-router-dom';

interface BadgeProps {
  text?: string;
  count?: number;
}

interface SidebarItemProps {
  icon: string;
  label: string;
  isActive?: boolean;
  hasSubmenu?: boolean;
  badge?: BadgeProps;
  path: string; // Add the path prop for routing
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, isActive, hasSubmenu, badge, path }) => {
  const baseClasses = "flex gap-4 px-4 py-3.5 mt-7 whitespace-nowrap text-4xl";
  const activeClasses = isActive ? "text-black bg-zinc-100 rounded-[30px]" : "text-neutral-500";
  const classes = `${baseClasses} ${activeClasses}`;

  return (
    <NavLink to={path} className={({ isActive }) => (isActive ? "active" : "")}>
      <div className={classes}>
        <img loading="lazy" src={icon} alt={label} className="object-contain shrink-0 w-6 aspect-square" />
        <div>{label}</div>
        {badge && (
          <div className="flex gap-2 ml-auto">
            {badge.text && (
              <div className="px-2 py-1 text-white bg-blue-400 rounded-md">{badge.text}</div>
            )}
            {badge.count && (
              <div className="px-2 w-6 h-6 text-black bg-emerald-200 rounded-md border border-emerald-200 border-solid">
                {badge.count}
              </div>
            )}
          </div>
        )}
        {hasSubmenu && (
          <img loading="lazy" src="/path-to-submenu-icon.png" alt="" className="object-contain shrink-0 w-6 aspect-square ml-auto" />
        )}
      </div>
    </NavLink>
  );
};

export default SidebarItem;
