// import React from 'react';
// import SearchBar from './SearchBar';
// import ProfileDropdown from './ProfileDropdown';
// import StatCards from './StatCards';
// import MeetingSchedule from './MeetingSchedule';
// import ApplicationTable from './ApplicationTable';

// interface MainContentProps {
//   stats: any[];
//   meetings: any[];
//   applications: any[];
//   onSelectProfile: (profileId: number) => void;  // Handle profile selection
//   selectedProfileId: number | null;  // Track selected profile ID
//   children: React.ReactNode;
// }

// const MainContent: React.FC<MainContentProps> = ({
//   stats,
//   meetings,
//   applications,
//   onSelectProfile,
//   selectedProfileId,
//   children,
// }) => {
//   return (
//     <main className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
//       <div className="flex flex-col pb-16 mt-5 w-full max-md:mt-10 max-md:max-w-full">
//         <div className="flex flex-col self-end mr-5 max-w-full w-[1277px] max-md:mr-2.5">
//           <SearchBar />
//           <div className="flex flex-wrap gap-1 justify-between mt-10 w-full max-md:max-w-full">
//             <div className="flex gap-1 self-end mt-10 text-4xl whitespace-nowrap text-neutral-900">
//               <div className="h-[30px]" style={{paddingRight: '4px', paddingLeft: '0px' , marginLeft: '-130px'}} >Hello,</div>
//               <div className="font-bold h-[30px]" style={{ paddingLeft: '0px' }} >James</div>
//             </div>
//             <ProfileDropdown onSelectProfile={onSelectProfile} />
//           </div>
//         </div>

//         {/* Pass selectedProfileId to StatCards */}
//         <StatCards profileId={selectedProfileId} stats={stats} />

//         {/* Main section below the stats */}
//         <div className="flex gap-5 mt-12 max-md:flex-col max-md:mr-2.5 w-full">
//           {/* Placeholder for Best Candidate */}
//           <div className="w-[2000px] bg-gray-100 rounded-lg shadow-lg p-6 h-75">
//             <h3 className="text-xl font-semibold text-gray-600">Best Candidate</h3>
//             <p className="text-gray-500 mt-4">Details of the best candidate will go here...</p>
//           </div>

//           {/* Meeting Schedule */}
//           <div className="w-2/3">
//             <MeetingSchedule meetings={meetings} />
//           </div>
//         </div>

//         {/* Applications Table */}
//         <div className="mt-8">
//           <ApplicationTable applicants={applications} />
//         </div>
//       </div>
//       {children}
//     </main>
//   );
// };

// export default MainContent;








// import React, { useMemo } from 'react';
// import SearchBar from './SearchBar';
// import ProfileDropdown from './ProfileDropdown';
// import StatCards from './StatCards';
// import MeetingSchedule from './MeetingSchedule';
// import ApplicationTable from './ApplicationTable';

// interface MainContentProps {
//   stats: any[];
//   meetings: any[];
//   applications: any[];
//   onSelectProfile: (profileId: number) => void; // Handle profile selection
//   selectedProfileId: number | null; // Track selected profile ID
//   children: React.ReactNode;
// }

// const MainContent: React.FC<MainContentProps> = ({
//   stats,
//   meetings,
//   applications,
//   onSelectProfile,
//   selectedProfileId,
//   children,
// }) => {
//   // Memoize the calculation to optimize performance
//   const bestCandidate = useMemo(() => {
//     if (!selectedProfileId) return null;

//     // Filter applications based on the selected profile
//     const filteredApplicants = applications.filter(
//       (applicant) => applicant.profile_id === selectedProfileId && applicant.status === 'Shortlisted'
//     );

//     // Assuming the "best" candidate is the one with the most experience
//     if (filteredApplicants.length > 0) {
//       return filteredApplicants.reduce((prev, current) => {
//         const prevExperience = parseFloat(prev.experience);
//         const currentExperience = parseFloat(current.experience);
//         return prevExperience > currentExperience ? prev : current;
//       });
//     }

//     return null;
//   }, [applications, selectedProfileId]);

//   return (
//     <main className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
//       <div className="flex flex-col pb-16 mt-5 w-full max-md:mt-10 max-md:max-w-full">
//         <div className="flex flex-col self-end mr-5 max-w-full w-[1277px] max-md:mr-2.5">
//           <SearchBar />
//           <div className="flex flex-wrap gap-1 justify-between mt-10 w-full max-md:max-w-full">
//             <div className="flex gap-1 self-end mt-10 text-4xl whitespace-nowrap text-neutral-900">
//               <div className="h-[30px]" style={{ paddingRight: '4px', paddingLeft: '0px', marginLeft: '-130px' }}>
//                 Hello,
//               </div>
//               <div className="font-bold h-[30px]" style={{ paddingLeft: '0px' }}>
//                 James
//               </div>
//             </div>
//             <ProfileDropdown onSelectProfile={onSelectProfile} />
//           </div>
//         </div>

//         {/* Pass selectedProfileId to StatCards */}
//         <StatCards profileId={selectedProfileId} stats={stats} />

//         {/* Main section below the stats */}
//         <div className="flex gap-5 mt-12 max-md:flex-col max-md:mr-2.5 w-full">
//           {/* Best Candidate Section */}
//           <div className="w-[2000px] bg-gray-100 rounded-lg shadow-lg p-6 h-75">
//             <h3 className="text-xl font-semibold text-gray-600">Best Candidate</h3>
//             {bestCandidate ? (
//               <div className="mt-4">
//                 <p><strong>Name:</strong> {bestCandidate.name}</p>
//                 <p><strong>Source:</strong> {bestCandidate.source}</p>
//                 <p><strong>Experience:</strong> {bestCandidate.experience}</p>
//                 <p><strong>Status:</strong> {bestCandidate.status}</p>
//               </div>
//             ) : (
//               <p className="text-gray-500 mt-4">No shortlisted candidates found for this profile...</p>
//             )}
//           </div>

//           {/* Meeting Schedule */}
//           <div className="w-2/3">
//             <MeetingSchedule meetings={meetings} />
//           </div>
//         </div>

//         {/* Applications Table */}
//         <div className="mt-8">
//           <ApplicationTable applicants={applications} />
//         </div>
//       </div>
//       {children}
//     </main>
//   );
// };

// export default MainContent;





// import React, { useMemo } from 'react';
// import SearchBar from './SearchBar';
// import ProfileDropdown from './ProfileDropdown';
// import StatCards from './StatCards';
// import MeetingSchedule from './MeetingSchedule';
// import ApplicationTable from './ApplicationTable';

// interface MainContentProps {
//   stats: any[];
//   meetings: any[];
//   applications: any[];
//   onSelectProfile: (profileId: number) => void; // Handle profile selection
//   selectedProfileId: number | null; // Track selected profile ID
//   children: React.ReactNode;
// }

// const MainContent: React.FC<MainContentProps> = ({
//   stats,
//   meetings,
//   applications,
//   onSelectProfile,
//   selectedProfileId,
//   children,
// }) => {
//   // Finding the best candidate based on the selected profile
//   const bestCandidate = useMemo(() => {
//     if (!selectedProfileId) return null;

//     // Filter applications based on the selected profile and status
//     const filteredApplicants = applications.filter(
//       (applicant) => applicant.profile_id === selectedProfileId && applicant.status === 'Shortlisted'
//     );

//     // Log the filtered applicants to ensure the right ones are being fetched
//     console.log('Filtered Applicants for Profile ID:', selectedProfileId, filteredApplicants);

//     // Assuming the "best" candidate is the one with the most experience
//     if (filteredApplicants.length > 0) {
//       return filteredApplicants.reduce((prev, current) => {
//         // Extract numeric value from experience string (e.g., "2 years" => 2)
//         const prevExperience = parseFloat(prev.experience.split(' ')[0]);
//         const currentExperience = parseFloat(current.experience.split(' ')[0]);
//         return prevExperience > currentExperience ? prev : current;
//       });
//     }

//     return null;
//   }, [applications, selectedProfileId]);

//   return (
//     <main className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
//       <div className="flex flex-col pb-16 mt-5 w-full max-md:mt-10 max-md:max-w-full">
//         <div className="flex flex-col self-end mr-5 max-w-full w-[1277px] max-md:mr-2.5">
//           <SearchBar />
//           <div className="flex flex-wrap gap-1 justify-between mt-10 w-full max-md:max-w-full">
//             <div className="flex gap-1 self-end mt-10 text-4xl whitespace-nowrap text-neutral-900">
//               <div className="h-[30px]" style={{ paddingRight: '4px', paddingLeft: '0px', marginLeft: '-130px' }}>
//                 Hello,
//               </div>
//               <div className="font-bold h-[30px]" style={{ paddingLeft: '0px' }}>
//                 James
//               </div>
//             </div>
//             <ProfileDropdown onSelectProfile={onSelectProfile} />
//           </div>
//         </div>

//         {/* Pass selectedProfileId to StatCards */}
//         <StatCards profileId={selectedProfileId} stats={stats} />

//         {/* Main section below the stats */}
//         <div className="flex gap-5 mt-12 max-md:flex-col max-md:mr-2.5 w-full">
//           {/* Best Candidate Section */}
//           <div className="w-[2000px] bg-gray-100 rounded-lg shadow-lg p-6 h-75">
//             <h3 className="text-xl font-semibold text-gray-600">Best Candidate</h3>
//             {bestCandidate ? (
//               <div className="mt-4 text-gray-700">
//                 <p>
//                   <strong>Name:</strong> {bestCandidate.name}
//                 </p>
//                 <p>
//                   <strong>Experience:</strong> {bestCandidate.experience}
//                 </p>
//                 <p>
//                   <strong>Source:</strong> {bestCandidate.source}
//                 </p>
//                 <p>
//                   <strong>Status:</strong> {bestCandidate.status}
//                 </p>
//               </div>
//             ) : (
//               <p className="text-gray-500 mt-4">No shortlisted candidates available for this profile.</p>
//             )}
//           </div>

//           {/* Meeting Schedule */}
//           <div className="w-2/3">
//             <MeetingSchedule meetings={meetings} />
//           </div>
//         </div>

//         {/* Applications Table */}
//         <div className="mt-8">
//           <ApplicationTable applicants={applications} />
//         </div>
//       </div>
//       {children}
//     </main>
//   );
// };

// export default MainContent;







import React, { useMemo } from 'react';
import SearchBar from './SearchBar';
import ProfileDropdown from './ProfileDropdown';
import StatCards from './StatCards';
import MeetingSchedule from './MeetingSchedule';
import ApplicationTable from './ApplicationTable';
import { FaUser, FaBriefcase, FaBuilding, FaAward } from 'react-icons/fa';

interface MainContentProps {
  stats: any[];
  meetings: any[];
  applications: any[];
  onSelectProfile: (profileId: number) => void; // Handle profile selection
  selectedProfileId: number | null; // Track selected profile ID
  children: React.ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({
  stats,
  meetings,
  applications,
  onSelectProfile,
  selectedProfileId,
  children,
}) => {
  // Finding the best candidate based on the selected profile
  const bestCandidate = useMemo(() => {
    if (!selectedProfileId) return null;

    // Filter applications based on the selected profile and status
    const filteredApplicants = applications.filter(
      (applicant) => applicant.profile_id === selectedProfileId && applicant.status === 'Shortlisted'
    );

    // Assuming the "best" candidate is the one with the most experience
    if (filteredApplicants.length > 0) {
      return filteredApplicants.reduce((prev, current) => {
        // Extract numeric value from experience string (e.g., "2 years" => 2)
        const prevExperience = parseFloat(prev.experience.split(' ')[0]);
        const currentExperience = parseFloat(current.experience.split(' ')[0]);
        return prevExperience > currentExperience ? prev : current;
      });
    }

    return null;
  }, [applications, selectedProfileId]);

  return (
    <main className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col pb-16 mt-5 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col self-end mr-5 max-w-full w-[1277px] max-md:mr-2.5">
          <SearchBar />
          <div className="flex flex-wrap gap-1 justify-between mt-10 w-full max-md:max-w-full">
            <div className="flex gap-1 self-end mt-10 text-4xl whitespace-nowrap text-neutral-900">
              <div className="h-[30px]" style={{ paddingRight: '4px', paddingLeft: '0px', marginLeft: '-130px' }}>
                Hello,
              </div>
              <div className="font-bold h-[30px]" style={{ paddingLeft: '0px' }}>
                James
              </div>
            </div>
            <ProfileDropdown onSelectProfile={onSelectProfile} />
          </div>
        </div>

        {/* Pass selectedProfileId to StatCards */}
        <StatCards profileId={selectedProfileId} stats={stats} />

        {/* Main section below the stats */}
        <div className="flex gap-5 mt-7 max-md:flex-col max-md:mr-2.5 w-full" >
          {/* Best Candidate Section */}
          <div className="w-[2000px] bg-white rounded-lg shadow-lg p-6" style={{marginLeft: '5px' }}>
            <h3 className="text-2xl font-semibold text-gray-800 mb-6" style={{ paddingLeft: '10px' }}>Best Candidate</h3>
            {bestCandidate ? (
              <div className="bg-gray-50 rounded-lg shadow-sm p-6 border border-gray-200">
                <div className="flex items-center gap-4 mb-6">
                  <FaUser className="text-4xl text-gray-700" />
                  <h3 className="text-3xl font-bold text-gray-900">{bestCandidate.name}</h3>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaBriefcase className="text-xl" />
                    <p className="text-xl">
                      <strong>Experience:</strong> {bestCandidate.experience}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaBuilding className="text-xl" />
                    <p className="text-xl">
                      <strong>Source:</strong> {bestCandidate.source}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaAward className="text-xl" />
                    <p className="text-xl">
                      <strong>Status:</strong> {bestCandidate.status}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-gray-50 rounded-lg shadow-sm p-6 border border-gray-200">
                <p className="text-gray-600 text-xl">No shortlisted candidates available for this profile.</p>
              </div>
            )}
          </div>

          {/* Meeting Schedule */}
          <div className="w-2/3">
            <MeetingSchedule meetings={meetings} />
          </div>
        </div>

        {/* Applications Table */}
        <div className="mt-2">
          <ApplicationTable applicants={applications} />
        </div>
      </div>
      {children}
    </main>
  );
};

export default MainContent;
