import React from 'react';

interface StatCardProps {
  title: string;
  value: number;
  percentage: number;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, percentage }) => {
  return (
    <div
      className="flex items-center justify-between p-6 rounded-lg bg-gray-100 shadow-lg"
      style={{ width: '425px', height: '160px' }}  // Adjust box dimensions here
    >
      {/* Left Section: Title and Value */}
      <div className="flex flex-col justify-center">
        {/* Increase text size for the title */}
        <div className="text-gray-500 text-3xl capitalize">{title}</div>  
        {/* Increase font size for the value */}
        <div className="text-6xl font-semibold mt-2">{value}</div>  
      </div>

      {/* Right Section: Circular Meter */}
      <div className="relative w-24 h-24 flex justify-center items-center">
        <div className="absolute w-full h-full">
          <svg viewBox="0 0 36 36" className="circular-chart orange">
            <path
              className="circle-bg"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#ececec"
              strokeWidth="2.5"
            />
            <path
              className="circle"
              strokeDasharray={`${percentage}, 100`}
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#FF8C00"  // Adjust the stroke color here
              strokeWidth="2.5"
            />
          </svg>
        </div>
        {/* Increase font size for the percentage */}
        <div className="absolute text-xl font-bold">
          {percentage}%
        </div>
      </div>
    </div>
  );
};

export default StatCard;
