// import React, { useState } from 'react';
// import axios from 'axios';
// import ResponseSidebar from './ResponseSidebar'; // Import the sidebar component

// const SearchBar: React.FC = () => {
//   const [query, setQuery] = useState<string>('');  // State to hold the user input
//   const [response, setResponse] = useState<string | null>(null);
//   const [showSidebar, setShowSidebar] = useState<boolean>(false);
//   const [loading, setLoading] = useState<boolean>(false);

//   const handleSearch = async (event: React.FormEvent) => {
//     event.preventDefault(); // Prevent page refresh
//     setLoading(true);

//     try {
//       // Send user input (query) as the prompt in the POST request
//       const res = await axios.post('http://localhost:8010/query', {
//         prompt: query  // Use the correct field name `prompt`
//       }, {
//         headers: {
//           'Content-Type': 'application/json',  // Ensure proper content type
//         }
//       });

//       setResponse(res.data.response.output); // Set the response
//       setShowSidebar(true); // Show the sidebar with the response
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <form
//         className="flex overflow-hidden flex-wrap gap-5 justify-between items-center self-center px-2 py-2 max-w-full bg-white rounded-[97px] shadow-[6px_2px_38px_rgba(255,202,40,0.3)] w-[600px] max-md:px-5"
//         style={{ marginLeft: '-300px'}}
//         onSubmit={handleSearch}  // Trigger handleSearch on form submit
//       >
//         <input
//           type="text"
//           id="search"
//           value={query}  // Bind the input value to query state
//           onChange={(e) => setQuery(e.target.value)}  // Update query state on input change
//           placeholder="Ask GenAI"
//           className="flex-grow text-2xl text-black text-opacity-90 bg-transparent border-none outline-none"
//           style={{ paddingLeft: '30px' }}
//         />
//         <button type="submit" className="flex relative flex-col justify-center self-stretch px-1 py-0.5 rounded-full aspect-[1.017] w-[40px]" style={{ paddingRight: '50px' }}>
//           Search
//         </button>
//       </form>

//       {/* Show loading indicator if request is in progress */}
//       {loading && <div>Loading...</div>}

//       {/* Sidebar for displaying the API response */}
//       {showSidebar && <ResponseSidebar response={response} onClose={() => setShowSidebar(false)} />}
//     </>
//   );
// };

// export default SearchBar;


//==============================================================================


// import React, { useState } from 'react';
// import axios from 'axios';

// const SearchBar: React.FC = () => {
//   const [query, setQuery] = useState<string>('');  // State to hold the user input
//   const [response, setResponse] = useState<string | null>(null);
//   const [loading, setLoading] = useState<boolean>(false);

//   const handleSearch = async (event: React.FormEvent) => {
//     event.preventDefault(); // Prevent page refresh
//     setLoading(true);

//     try {
//       // Send user input (query) as the prompt in the POST request
//       const res = await axios.post('http://localhost:8010/query', {
//         prompt: query  // Use the value entered by the user
//       });

//       setResponse(res.data.response.output); // Set the response
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="w-full">
//       {/* Search Bar */}
//       <form
//         className="flex overflow-hidden flex-wrap gap-5 justify-between items-center self-center px-2 py-2 max-w-full bg-white rounded-[97px] shadow-[6px_2px_38px_rgba(255,202,40,0.3)] w-[600px] max-md:px-5"
//         style={{ marginLeft: '200px'}}
//         onSubmit={handleSearch}  // Trigger handleSearch on form submit
//       >
//         <input
//           type="text"
//           id="search"
//           value={query}  // Bind the input value to query state
//           onChange={(e) => setQuery(e.target.value)}  // Update query state on input change
//           placeholder="Ask GenAI"
//           className="flex-grow text-4xl text-black text-opacity-50 bg-transparent border-none outline-none border-gray-900"
//           style={{ paddingLeft: '30px' }}
//         />
//         <button type="submit" className="flex relative flex-col justify-center self-stretch px-1 py-0.5 rounded-full aspect-[1.017] w-[40px]" style={{ paddingRight: '50px' }}>
//           Search
//         </button>
//       </form>

//       {/* Show loading indicator if request is in progress */}
//       {loading && <div className="text-center mt-5" style={{ marginLeft: '-250px'}}>Loading...</div>}

//       {/* Response Box */}
//       {response && (
//         <div className="mt-6 p-6 bg-white rounded-xl shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105">
//             <div className="flex justify-between items-center mb-4">
//             <h3 className="text-2xl font-semibold text-blue-600">Response</h3>
//             <svg className="w-6 h-6 text-blue-600 cursor-pointer" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
//             </div>
//             <hr className="border-gray-200 mb-4"/>
//             <p className="text-gray-700 leading-relaxed">
//             {response}
//             </p>
//             <div className="mt-4 text-right">
//             <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition-colors duration-200 ease-in-out">
//                 Take Action
//             </button>
//             </div>
//         </div>
//         )}

//     </div>
//   );
// };

// export default SearchBar;





import React, { useState } from 'react';
import axios from 'axios';

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>('');  // State to hold the user input
  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent page refresh
    setLoading(true);

    try {
      // Send user input (query) as the prompt in the POST request
      const res = await axios.post('http://10.0.0.220:8010/query', {
        prompt: query  // Use the correct field name `prompt`
      }, {
        headers: {
          'Content-Type': 'application/json',  // Ensure proper content type
        }
      });

      setResponse(res.data.response.output); // Set the response
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle close (hide the response box)
  const handleClose = () => {
    setResponse(null);  // Set the response to null to hide the box
  };

  return (
    <>
      <form
        className="flex overflow-hidden flex-wrap gap-5 justify-between items-center self-center px-2 py-4 max-w-full bg-white rounded-[97px] shadow-[6px_2px_38px_rgba(255,202,40,0.3)] w-[600px] max-md:px-5"
        style={{ marginLeft: '-200px' }}
        onSubmit={handleSearch}  // Trigger handleSearch on form submit
      >
        <input
          type="text"
          id="search"
          value={query}  // Bind the input value to query state
          onChange={(e) => setQuery(e.target.value)}  // Update query state on input change
          placeholder="Ask GenAI"
          className="flex-grow text-4xl text-black text-opacity-50 bg-transparent border-none outline-none border-gray-900"
          style={{ paddingLeft: '30px' }}
        />
        <button type="submit" className="flex relative flex-col justify-center self-stretch px-1 py-0.5 rounded-full aspect-[1.017] w-[40px]" style={{ paddingRight: '50px' }}>
          Search
        </button>
      </form>

      {/* Show loading indicator if request is in progress */}
      {loading && <div className="text-center mt-5" style={{ marginLeft: '-250px'}}>Loading...</div>}

      {/* Response Box with Close (X) Button */}
      {response && (
        <div className="mt-6 p-10 bg-gray-50 rounded-xl shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 relative" style={{ marginLeft: '-130px'}}>
          {/* Close (X) Button */}
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 transition-colors duration-200"
          >
            ✕
          </button>

          <div className="flex justify-between items-center">
            <h3 className="text-2xl font-semibold text-black-600">Response</h3>
          </div>
          <hr className="border-gray-200 mb-4" />
          <p className="text-gray-700 leading-relaxed text-2xl">
            {response}
          </p>
        </div>
      )}
    </>
  );
};

export default SearchBar;
