// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* src/components/Loader.css */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 9999;
  }
  
  .loader-content {
    text-align: center;
  }
  
  .loader-image {
    width: 250px; /* Adjust as per your image size */
    margin-bottom: 20px;
  }
  
  .loading-dots {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    margin: 0 4px;
    background-color: #000; /* Color for the dots */
    border-radius: 50%;
    animation: dot-blink 1.5s infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes dot-blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  p {
    font-size: 18px;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":";;AAEA,8BAA8B;AAC9B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0CAA0C,EAAE,gCAAgC;IAC5E,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY,EAAE,kCAAkC;IAChD,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB,EAAE,uBAAuB;IAC/C,kBAAkB;IAClB,kCAAkC;EACpC;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":["\n\n/* src/components/Loader.css */\n.loader-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */\n    z-index: 9999;\n  }\n  \n  .loader-content {\n    text-align: center;\n  }\n  \n  .loader-image {\n    width: 250px; /* Adjust as per your image size */\n    margin-bottom: 20px;\n  }\n  \n  .loading-dots {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 20px;\n  }\n  \n  .dot {\n    width: 12px;\n    height: 12px;\n    margin: 0 4px;\n    background-color: #000; /* Color for the dots */\n    border-radius: 50%;\n    animation: dot-blink 1.5s infinite;\n  }\n  \n  .dot:nth-child(2) {\n    animation-delay: 0.3s;\n  }\n  \n  .dot:nth-child(3) {\n    animation-delay: 0.6s;\n  }\n  \n  @keyframes dot-blink {\n    0%, 80%, 100% {\n      opacity: 0;\n    }\n    40% {\n      opacity: 1;\n    }\n  }\n  \n  p {\n    font-size: 18px;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
