import React from 'react';

interface InputFieldProps {
  label: string;
  type: string;
  name: string; // Added to capture the name of the input
  value: string; // Added to capture the value of the input
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Added to handle input changes
}

const InputField: React.FC<InputFieldProps> = ({ label, type, name, value, onChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-500 text-2xl">{label}</label>
      <input
        type={type}
        name={name} // Make sure to pass the name prop
        value={value} // Make sure to pass the value prop
        onChange={onChange} // Make sure to pass the onChange prop
        className="w-full px-4 py-3 border rounded"
        required
      />
    </div>
  );
};

export default InputField;
