// src/components/Loader.tsx

import React from 'react';
import './Loader.css'; // Make sure the CSS is imported for the animation
import loaderImage from '../assets/images/loader_image.png'; // Add your loader image path here

const Loader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader-content">
        <img src={loaderImage} alt="Loader" className="loader-image" />
        <div className="loading-dots">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
        <p>Processing your request...</p>
      </div>
    </div>
  );
};

export default Loader;
