// // // // import React from 'react';
// // // // import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// // // // import Header from './components/Header';
// // // // import Signup from './components/Signup';
// // // // import Login from './components/Login';
// // // // import ResumeJDMatching from './components/ResumeJDMatching';
// // // // import xenonstack_logo from '/Users/xs334-amakum/Desktop/idp/src/assets/images/xenonstack_logo.svg';

// // // // const App: React.FC = () => {
// // // //   return (
// // // //     <Router>
// // // //       {/* Header Component will be rendered on all pages */}
// // // //       <Header logoSrc={xenonstack_logo} />
      
// // // //       {/* Route Definitions */}
// // // //       <Routes>
// // // //         {/* Login Page - This should be the default page */}
// // // //         <Route path="/" element={<Login onSignup={() => console.log('Navigate to Signup')} />} />

// // // //         {/* Resume JD Matching Page */}
// // // //         <Route path="/resume-matching" element={<ResumeJDMatching />} />

// // // //         {/* Signup Page */}
// // // //         <Route path="/signup" element={<Signup onLogin={() => console.log('Navigate to Login')} />} />
// // // //       </Routes>
// // // //     </Router>
// // // //   );
// // // // };

// // // // export default App;




// // import React, { useState } from 'react';
// // import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// // import Header from './components/Header';
// // import Signup from './components/Signup';
// // import Login from './components/Login';
// // import ResumeJDMatching from './components/ResumeJDMatching';
// // import xenonstack_logo from '/Users/xs334-amakum/Desktop/idp/src/assets/images/xenonstack_logo.svg';

// // const App: React.FC = () => {
// //   const [isAuthenticated, setIsAuthenticated] = useState(false);

// //   const handleLogin = () => {
// //     setIsAuthenticated(true);
// //   };

// //   const handleLogout = () => {
// //     setIsAuthenticated(false);
// //   };

// //   return (
// //     <Router>
// //       {/* Header Component will be rendered on all pages */}
// //       <Header logoSrc={xenonstack_logo} isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      
// //       {/* Route Definitions */}
// //       <Routes>
// //         {/* Redirect to Login if not authenticated */}
// //         <Route
// //           path="/"
// //           element={isAuthenticated ? <ResumeJDMatching /> : <Navigate to="/login" />}
// //         />
// //         <Route
// //           path="/signup"
// //           element={<Signup onLogin={handleLogin} />}
// //         />
// //         <Route
// //           path="/login"
// //           element={<Login onSignup={() => console.log('Navigate to Signup')} onLogin={handleLogin} />}
// //         />
// //       </Routes>
// //     </Router>
// //   );
// // };

// // export default App;










// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Header from './components/Header';
// import Signup from './components/Signup';
// import Login from './components/Login';
// import ResumeJDMatching from './components/ResumeJDMatching';
// import Dashboard from './pages/Dashboard'; // Assuming you have a Dashboard component
// import xenonstack_logo from '/Users/xs334-amakum/Desktop/idp/src/assets/images/xenonstack_logo.svg';

// const App: React.FC = () => {
//   // Initialize state from localStorage
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
//     localStorage.getItem('isAuthenticated') === 'true' || false
//   );

//   // Effect to store login state in localStorage whenever it changes
//   useEffect(() => {
//     localStorage.setItem('isAuthenticated', isAuthenticated.toString());
//   }, [isAuthenticated]);

//   const handleLogin = () => {
//     setIsAuthenticated(true);
//   };

//   const handleLogout = () => {
//     setIsAuthenticated(false);
//     localStorage.removeItem('isAuthenticated'); // Clear auth state when logged out
//   };

//   return (
//     <Router>
//       {/* Header Component will be rendered on all pages */}
//       <Header logoSrc={xenonstack_logo} isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      
//       {/* Route Definitions */}
//       <Routes>
//         {/* Redirect to Login if not authenticated */}
//         <Route
//           path="/"
//           element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
//         />
//         <Route
//           path="/signup"
//           element={<Signup onLogin={handleLogin} />}
//         />
//         <Route
//           path="/login"
//           element={<Login onSignup={() => console.log('Navigate to Signup')} onLogin={handleLogin} />}
//         />
//         {/* Dashboard route after login */}
//         <Route
//           path="/dashboard"
//           element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
//         />
//       </Routes>
//     </Router>
//   );
// };

// export default App;





// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Header from './components/Header';
// import Signup from './components/Signup';
// import Login from './components/Login';
// import ResumeJDMatching from './components/ResumeJDMatching';  // Import ResumeJDMatching component
// import Dashboard from './pages/Dashboard'; 
// import xenonstack_logo from '/Users/xs334-amakum/Desktop/idp/src/assets/images/xenonstack_logo.svg';

// const App: React.FC = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
//     localStorage.getItem('isAuthenticated') === 'true' || false
//   );

//   useEffect(() => {
//     localStorage.setItem('isAuthenticated', isAuthenticated.toString());
//   }, [isAuthenticated]);

//   const handleLogin = () => {
//     setIsAuthenticated(true);
//   };

//   const handleLogout = () => {
//     setIsAuthenticated(false);
//     localStorage.removeItem('isAuthenticated'); 
//   };

//   return (
//     <Router>
//       <Header logoSrc={xenonstack_logo} isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      
//       <Routes>
//         <Route
//           path="/"
//           element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
//         />
//         <Route
//           path="/signup"
//           element={<Signup onLogin={handleLogin} />}
//         />
//         <Route
//           path="/login"
//           element={<Login onSignup={() => console.log('Navigate to Signup')} onLogin={handleLogin} />}
//         />
//         <Route
//           path="/dashboard"
//           element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
//         />
        
//         {/* New Route for Resume Matching */}
//         <Route
//           path="/resume-matching"
//           element={isAuthenticated ? <ResumeJDMatching /> : <Navigate to="/login" />}
//         />
//       </Routes>
//     </Router>
//   );
// };

// export default App;





import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Signup from './components/Signup';
import Login from './components/Login';
import ResumeJDMatching from './components/ResumeJDMatching';
import Dashboard from './pages/Dashboard'; 
import PostJobPage from './components/PostJobPage';
import HistoryPage from './components/HistoryPage';
import ServicePage from './components/ServicePage';
import xenonstack_logo from './assets/images/xenonstack_logo.svg'
const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem('isAuthenticated') === 'true' || false
  );

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated.toString());
  }, [isAuthenticated]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); 
  };

  return (
    <Router>
      <Header logoSrc={xenonstack_logo} isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
        <Route path="/signup" element={<Signup onLogin={handleLogin} />} />
        <Route path="/login" element={<Login onSignup={() => console.log('Navigate to Signup')} onLogin={handleLogin} />} />
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/resume-matching" element={isAuthenticated ? <ResumeJDMatching /> : <Navigate to="/login" />} />
        <Route path="/post-job" element={isAuthenticated ? <PostJobPage /> : <Navigate to="/login" />} />
        <Route path="/history" element={isAuthenticated ? <HistoryPage /> : <Navigate to="/login" />} />
        <Route path="/service" element={isAuthenticated ? <ServicePage /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
