import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PostJobPage: React.FC = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobRequirements, setJobRequirements] = useState('');
  const [isLinkedInAuthenticated, setIsLinkedInAuthenticated] = useState(false);
  const navigate = useNavigate();

  // Check if LinkedIn access token is available in localStorage
  useEffect(() => {
    const accessToken = localStorage.getItem('linkedin_access_token');
    if (accessToken) {
      setIsLinkedInAuthenticated(true);
    }
  }, []);

  const client_id = '86rxb1dat9fr62'; // Your LinkedIn app's client ID
  const redirect_uri = 'http://10.0.0.220:3000/linkedin/callback'; // Adjust redirect_uri to your callback page
  const state = 'random_string_for_security';
  const scope = 'r_liteprofile r_emailaddress w_member_social';

  const handleLinkedInLogin = () => {
    // Redirect to LinkedIn OAuth page
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${state}&scope=${encodeURIComponent(scope)}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLinkedInAuthenticated) {
      alert('You need to log in with LinkedIn first.');
      return;
    }

    try {
      const accessToken = localStorage.getItem('linkedin_access_token');
      if (!accessToken) {
        throw new Error('LinkedIn access token is missing.');
      }

      // Make an API call to LinkedIn to post the job
      const response = await fetch(
        'https://api.linkedin.com/v2/shares',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            owner: 'urn:li:person:YOUR_PERSON_ID', // Replace with appropriate URN
            subject: jobTitle,
            text: {
              text: `${jobTitle} - ${jobDescription}\nRequirements: ${jobRequirements}`,
            },
            distribution: {
              linkedInDistributionTarget: {},
            },
          }),
        }
      );

      if (response.ok) {
        alert('Job posted successfully!');
      } else {
        alert('Failed to post job.');
      }
    } catch (error) {
      console.error('Error posting job:', error);
      alert('Failed to post job.');
    }
  };

  return (
    <div className="container mx-auto mt-10 p-5">
      <h1 className="text-3xl font-bold mb-5">Post a Job</h1>

      <button
        onClick={handleLinkedInLogin}
        className="px-4 py-2 bg-blue-600 text-white rounded mb-6"
      >
        {isLinkedInAuthenticated ? 'Logged in with LinkedIn' : 'Login with LinkedIn'}
      </button>

      <form onSubmit={handleSubmit} className="bg-white p-5 shadow-md rounded">
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="jobTitle">Job Title</label>
          <input
            type="text"
            id="jobTitle"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
            placeholder="e.g. Software Engineer"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="jobDescription">Job Description</label>
          <textarea
            id="jobDescription"
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
            placeholder="Describe the role and responsibilities"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="jobRequirements">Job Requirements</label>
          <textarea
            id="jobRequirements"
            value={jobRequirements}
            onChange={(e) => setJobRequirements(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
            placeholder="List the required skills, qualifications, etc."
            required
          />
        </div>

        <button type="submit" className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600">
          Post Job
        </button>
      </form>
    </div>
  );
};

export default PostJobPage;
