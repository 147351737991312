import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StatCard from './StatCard';

interface StatData {
  title: string;
  value: number;
  percentage: number;
}

interface StatCardsProps {
  profileId: number | null;
  stats: StatData[];
}

const StatCards: React.FC<StatCardsProps> = ({ profileId, stats }) => {
  const [statData, setStatData] = useState<StatData[]>([]);

  useEffect(() => {
    const fetchStats = async () => {
      if (profileId !== null) {
        try {
          const response = await axios.get(`http://10.0.0.220:8009/stats?profile_id=${profileId}`);
          const data = response.data;

          // Assuming the backend provides raw numbers and you calculate percentages here
          const total = data[0].total_applications;
          const rejected = data[0].rejected_applications;
          const shortlisted = data[0].shortlisted_applications;

          // Set the stats in the correct format for each box
          setStatData([
            { title: 'Total Applications', value: total, percentage: 100 }, // 100% full circle
            { title: 'Rejected Applications', value: rejected, percentage: Math.round((rejected / total) * 100) },
            { title: 'Shortlisted Applications', value: shortlisted, percentage: Math.round((shortlisted / total) * 100) },
          ]);
        } catch (error) {
          console.error('Error fetching stats:', error);
        }
      }
    };
    fetchStats();
  }, [profileId]);

  return (
    <div className="flex justify-between gap-6 w-full mt-8">
      {statData.map((stat, index) => (
        <StatCard key={index} {...stat} />
      ))}
    </div>
  );
};

export default StatCards;
