import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import SocialButton from './SocialButton';
import InputField from './InputField';
import Button from './Button';
import axios from 'axios';
import xenonstack_logo from '../assets/images/xenonstack_logo.svg'; 
import sidebarImage from '../assets/images/signup_2.svg'; 

interface SignupProps {
  onLogin: () => void;
}

const Signup: React.FC<SignupProps> = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignup = async () => {
    try {
      const response = await axios.post('http://10.0.0.220:5000/signup', {
        name: formData.fullName,
        email: formData.email,
        password: formData.password,
      });

      console.log('Signup successful:', response.data);
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Redirect to login after 2 seconds
    } catch (error) {
      console.error('Error during signup:', error);
      setSuccess(false);
    }
  };

  const socialButtons = [
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/edbe81f15e213a12023344a625122e4d85ce0adc894cc35d4db457871580e889?placeholderIfAbsent=true&apiKey=6e0d7b98af9d40898559255c7d27a150',
      text: 'Continue with Google',
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/6bb407ea51924e58303699876ff200b38402307348da0fed6031293b508fac69?placeholderIfAbsent=true&apiKey=6e0d7b98af9d40898559255c7d27a150',
      text: 'Continue with Facebook',
    },
  ];

  return (
    <div className="flex flex-col justify-center min-h-screen bg-gray-100">
      <div className="flex justify-center items-center w-full max-w-[85%] mx-auto">
        <div className="flex w-full gap-2 max-md:flex-col min-h-[85vh]">
          {/* Sidebar Section */}
          <aside className="flex flex-col w-[30%] bg-blue-200 text-white max-md:ml-0 max-md:w-full p-8 rounded-lg border-2 border-black">
            <div className="flex flex-col items-center">
              <img src={xenonstack_logo} alt="Logo" className="w-25 h-40" />
              <img src={sidebarImage} alt="Sidebar Graphic" className="mt-10 w-full h-auto rounded-lg" />
            </div>
          </aside>

          {/* Main Form Section */}
          <section className="flex flex-col flex-grow bg-white px-10 py-14 rounded-lg shadow-lg min-h-[85vh] border-2 border-black">
            <div className="flex flex-col text-sm font-medium text-neutral-400">
              <h1 className="self-start mt-8 text-3xl font-semibold text-neutral-800 max-md:mt-10">
                Create Account
              </h1>
              {success ? (
                <div className="text-center text-green-600 text-2xl">
                  ✅ Signup Successful!
                </div>
              ) : (
                <>
                  <div className="flex gap-4 self-center mt-8 max-w-full text-xs font-semibold w-full max-w-md">
                    {socialButtons.map((button, index) => (
                      <SocialButton key={index} icon={button.icon} text={button.text} />
                    ))}
                  </div>
                  <div className="self-center mt-10">- OR -</div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <InputField
                      label="Full Name"
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Email Address"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <Button text="Create Account" onClick={handleSignup} />
                  </form>
                  <p className="self-start mt-6">
                    Already have an account?{' '}
                    <button onClick={onLogin} className="text-slate-400">
                      Login
                    </button>
                  </p>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Signup;
