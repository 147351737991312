// import React, { useState } from 'react';
// import axios from 'axios';
// import PDFUploader from './PDFUploader';
// import Loader from './Loader'; // Loader component for showing animation
// import resume_matching_5 from '../assets/images/resume_matching_5.jpg';

// const ResumeJDMatching: React.FC = () => {
//   const [resumeUploaded, setResumeUploaded] = useState(false);
//   const [jdUploaded, setJdUploaded] = useState(false);
//   const [resumeFile, setResumeFile] = useState<File | null>(null);
//   const [jdFile, setJdFile] = useState<File | null>(null);
//   const [resumeFileName, setResumeFileName] = useState('');
//   const [jdFileName, setJdFileName] = useState('');
//   const [loading, setLoading] = useState(false); // State for loader
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [uploadError, setUploadError] = useState(false);
//   const [results, setResults] = useState<any | null>(null); // State to store matching results

//   const handleUploadComplete = (label: string, uploaded: boolean, fileName: string, file: File | null) => {
//     if (label === 'resume') {
//       setResumeUploaded(uploaded);
//       setResumeFileName(fileName);
//       setResumeFile(file);
//     } else if (label === 'jd') {
//       setJdUploaded(uploaded);
//       setJdFileName(fileName);
//       setJdFile(file);
//     }
//   };

//   const handleSubmit = async () => {
//     if (resumeUploaded && jdUploaded && resumeFile && jdFile) {
//       setLoading(true); // Show loader when submission starts

//       const formData = new FormData();
//       formData.append('jd_file', jdFile);
//       formData.append('cvs', resumeFile);

//       try {
//         const response = await axios.post('http://localhost:8003/process-cvs', formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         setLoading(false); // Hide loader
//         setResults(response.data); // Store the results from the backend
//         setUploadSuccess(true);
//         setUploadError(false);
//       } catch (error) {
//         setLoading(false); // Hide loader on error
//         setUploadError(true);
//         setUploadSuccess(false);
//       }
//     }
//   };

//   // Render the results after processing
//   const renderResults = () => {
//     if (!results) return null;

//     return (
//       <div className="results-container">
//         <h2>Resume-JD Matching Results</h2>

//         {results.sorted_candidates.map((candidate: any, index: number) => (
//           <div key={index} className="candidate-result">
//             <h3>Candidate {index + 1}:</h3>
//             <p><strong>Matching Skills:</strong> {candidate.matched_skills_profile.matching_skills.join(', ')}</p>
//             <p><strong>Missing Skills:</strong> {candidate.matched_skills_profile.missing_skills.join(', ')}</p>
//             <p><strong>Social Skills:</strong> {candidate.matched_skills_profile.social_skills[0].join(', ')}</p>
//             <p><strong>Score:</strong> {candidate.score}</p>
//             <p><strong>Score Breakdown:</strong> {candidate.breakdown}</p>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div className="flex flex-col pb-40 bg-white max-md:pb-24">
//       <main className="flex flex-wrap gap-8 self-center mt-10 ml-11 max-w-full w-[1304px] max-md:mt-10">
//         {loading ? (
//           <Loader /> // Show loader while processing
//         ) : results ? (
//           renderResults() // Show results after processing
//         ) : (
//           <>
//             <section className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
//               <div className="w-full max-md:max-w-full">
//                 <div className="flex gap-5 max-md:flex-col">
//                   <div className="flex flex-col w-[39%] max-md:ml-0 max-md:w-full">
//                     <div className="flex flex-col items-start w-full font-semibold text-zinc-700 max-md:mt-10">
//                       <img
//                         loading="lazy"
//                         src={resume_matching_5}
//                         alt="Resume JD Matching illustration"
//                         className="object-contain self-stretch w-full aspect-[1.5] rounded-[32px] border-4 border-black"
//                       />
//                       <h1 className="mt-5 text-6xl leading-[68px] max-md:mt-5 max-md:text-4xl max-md:leading-[50px]">
//                         Resume JD Matching
//                       </h1>
//                     </div>
//                   </div>
//                   <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full">
//                     <div className="mt-2 max-md:mt-10 max-md:max-w-full">
//                       <div className="flex flex-col gap-8 max-md:flex-col">
//                         {/* First upload box for the Resume */}
//                         <div className="flex flex-col items-center">
//                           <PDFUploader
//                             label="resume"
//                             onUploadComplete={(label, uploaded, fileName, file) =>
//                               handleUploadComplete(label, uploaded, fileName, file)
//                             }
//                           />
//                           <p className="mt-2 text-center text-lg font-semibold">Upload Resume</p>
//                           {resumeFileName && <p className="mt-2 text-center text-sm text-gray-500">{resumeFileName}</p>}
//                         </div>

//                         {/* Second upload box for the Job Description */}
//                         <div className="flex flex-col items-center">
//                           <PDFUploader
//                             label="jd"
//                             onUploadComplete={(label, uploaded, fileName, file) =>
//                               handleUploadComplete(label, uploaded, fileName, file)
//                             }
//                           />
//                           <p className="mt-2 text-center text-lg font-semibold">Upload Job Description (JD)</p>
//                           {jdFileName && <p className="mt-2 text-center text-sm text-gray-500">{jdFileName}</p>}
//                         </div>

//                         {/* Submit button */}
//                         <div className="flex justify-center w-full mt-6">
//                           <button
//                             className={`px-6 py-3 bg-pink-600 text-white rounded-full ${
//                               resumeUploaded && jdUploaded ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
//                             }`}
//                             disabled={!resumeUploaded || !jdUploaded}
//                             onClick={handleSubmit}
//                           >
//                             Submit
//                           </button>
//                         </div>

//                         {uploadSuccess && (
//                           <p className="mt-4 text-center text-green-600">Files uploaded successfully!</p>
//                         )}

//                         {uploadError && (
//                           <p className="mt-4 text-center text-red-600">Error uploading files. Please try again.</p>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </section>
//           </>
//         )}
//       </main>
//     </div>
//   );
// };

// export default ResumeJDMatching;


//===================================== Working

// import React, { useState } from 'react';
// import axios from 'axios';
// import PDFUploader from './PDFUploader';
// import Loader from './Loader'; // Loader component for showing animation
// import resume_matching_5 from '../assets/images/resume_matching_5.jpg';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(ArcElement, Tooltip, Legend);

// const ResumeJDMatching: React.FC = () => {
//   const [resumeUploaded, setResumeUploaded] = useState(false);
//   const [jdUploaded, setJdUploaded] = useState(false);
//   const [resumeFile, setResumeFile] = useState<File | null>(null);
//   const [jdFile, setJdFile] = useState<File | null>(null);
//   const [resumeFileName, setResumeFileName] = useState('');
//   const [jdFileName, setJdFileName] = useState('');
//   const [loading, setLoading] = useState(false); // State for loader
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [uploadError, setUploadError] = useState(false);
//   const [results, setResults] = useState<any | null>(null); // State to store matching results

//   const handleUploadComplete = (label: string, uploaded: boolean, fileName: string, file: File | null) => {
//     if (label === 'resume') {
//       setResumeUploaded(uploaded);
//       setResumeFileName(fileName);
//       setResumeFile(file);
//     } else if (label === 'jd') {
//       setJdUploaded(uploaded);
//       setJdFileName(fileName);
//       setJdFile(file);
//     }
//   };

//   const handleSubmit = async () => {
//     if (resumeUploaded && jdUploaded && resumeFile && jdFile) {
//       setLoading(true); // Show loader when submission starts

//       const formData = new FormData();
//       formData.append('jd_file', jdFile);
//       formData.append('cvs', resumeFile);

//       try {
//         const response = await axios.post('http://localhost:8003/process-cvs', formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         setLoading(false); // Hide loader
//         setResults(response.data); // Store the results from the backend
//         setUploadSuccess(true);
//         setUploadError(false);
//       } catch (error) {
//         setLoading(false); // Hide loader on error
//         setUploadError(true);
//         setUploadSuccess(false);
//       }
//     }
//   };

//   // Function to create the data for the pie chart
//   const createPieData = (score: number) => ({
//     labels: ['Score', 'Remaining'],
//     datasets: [
//       {
//         label: 'Candidate Score',
//         data: [score, 5 - score], // Assuming max score is 5
//         backgroundColor: ['#4CAF50', '#F44336'], // Green for score, Red for remaining
//         hoverOffset: 4,
//       },
//     ],
//   });

//   // Render the results after processing
//   const renderResults = () => {
//     if (!results) return null;

//     return (
//       <div className="results-container p-6 bg-gray-50 rounded-lg shadow-md mt-6">
//         <h2 className="text-3xl font-bold text-center mb-8">Resume-JD Matching Results</h2>

//         {results.sorted_candidates.map((candidate: any, index: number) => (
//           <div key={index} className="candidate-result p-6 bg-white rounded-lg shadow-md mb-8 flex gap-6">
//             {/* Text details of candidate */}
//             <div className="flex-grow">
//               <h3 className="text-2xl font-bold mb-4">Candidate {index + 1}</h3>

//               {/* Matching Skills */}
//               <p className="text-lg">
//                 <strong>Matching Skills:</strong>{' '}
//                 <span className="text-green-600">{candidate.matched_skills_profile.matching_skills.join(', ')}</span>
//               </p>

//               {/* Missing Skills */}
//               <p className="text-lg mt-2">
//                 <strong>Missing Skills:</strong>{' '}
//                 <span className="text-red-600">{candidate.matched_skills_profile.missing_skills.join(', ')}</span>
//               </p>

//               {/* Social Skills */}
//               <p className="text-lg mt-2">
//                 <strong>Social Skills:</strong>{' '}
//                 {candidate.matched_skills_profile.social_skills[0].join(', ')}
//               </p>

//               {/* Score */}
//               <p className="text-lg mt-4">
//                 <strong>Score:</strong> <span className="text-blue-600">{candidate.score}</span>
//               </p>
//             </div>

//             {/* Pie chart for the score */}
//             <div className="w-[200px]">
//               <Pie data={createPieData(candidate.score)} />
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div className="flex flex-col pb-40 bg-white max-md:pb-24">
//       <main className="flex flex-wrap gap-8 self-center mt-10 ml-11 max-w-full w-[1304px] max-md:mt-10">
//         {loading ? (
//           <Loader /> // Show loader while processing
//         ) : results ? (
//           renderResults() // Show results after processing
//         ) : (
//           <>
//             <section className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
//               <div className="w-full max-md:max-w-full">
//                 <div className="flex gap-5 max-md:flex-col">
//                   <div className="flex flex-col w-[39%] max-md:ml-0 max-md:w-full">
//                     <div className="flex flex-col items-start w-full font-semibold text-zinc-700 max-md:mt-10">
//                       <img
//                         loading="lazy"
//                         src={resume_matching_5}
//                         alt="Resume JD Matching illustration"
//                         className="object-contain self-stretch w-full aspect-[1.5] rounded-[32px] border-4 border-black"
//                       />
//                       <h1 className="mt-5 text-6xl leading-[68px] max-md:mt-5 max-md:text-4xl max-md:leading-[50px]">
//                         Resume JD Matching
//                       </h1>
//                     </div>
//                   </div>
//                   <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full">
//                     <div className="mt-2 max-md:mt-10 max-md:max-w-full">
//                       <div className="flex flex-col gap-8 max-md:flex-col">
//                         {/* First upload box for the Resume */}
//                         <div className="flex flex-col items-center">
//                           <PDFUploader
//                             label="resume"
//                             onUploadComplete={(label, uploaded, fileName, file) =>
//                               handleUploadComplete(label, uploaded, fileName, file)
//                             }
//                           />
//                           <p className="mt-2 text-center text-lg font-semibold">Upload Resume</p>
//                           {resumeFileName && <p className="mt-2 text-center text-sm text-gray-500">{resumeFileName}</p>}
//                         </div>

//                         {/* Second upload box for the Job Description */}
//                         <div className="flex flex-col items-center">
//                           <PDFUploader
//                             label="jd"
//                             onUploadComplete={(label, uploaded, fileName, file) =>
//                               handleUploadComplete(label, uploaded, fileName, file)
//                             }
//                           />
//                           <p className="mt-2 text-center text-lg font-semibold">Upload Job Description (JD)</p>
//                           {jdFileName && <p className="mt-2 text-center text-sm text-gray-500">{jdFileName}</p>}
//                         </div>

//                         {/* Submit button */}
//                         <div className="flex justify-center w-full mt-6">
//                           <button
//                             className={`px-6 py-3 bg-pink-600 text-white rounded-full ${
//                               resumeUploaded && jdUploaded ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
//                             }`}
//                             disabled={!resumeUploaded || !jdUploaded}
//                             onClick={handleSubmit}
//                           >
//                             Submit
//                           </button>
//                         </div>

//                         {uploadSuccess && (
//                           <p className="mt-4 text-center text-green-600">Files uploaded successfully!</p>
//                         )}

//                         {uploadError && (
//                           <p className="mt-4 text-center text-red-600">Error uploading files. Please try again.</p>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </section>
//           </>
//         )}
//       </main>
//     </div>
//   );
// };

// export default ResumeJDMatching;


import React, { useState } from 'react';
import axios from 'axios';
import PDFUploader from './PDFUploader';
import Loader from './Loader'; // Loader component for showing animation
import resume_matching_5 from '../assets/images/resume_matching_5.jpg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ResumeJDMatching: React.FC = () => {
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [jdUploaded, setJdUploaded] = useState(false);
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [jdFile, setJdFile] = useState<File | null>(null);
  const [resumeFileName, setResumeFileName] = useState('');
  const [jdFileName, setJdFileName] = useState('');
  const [loading, setLoading] = useState(false); // State for loader
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [results, setResults] = useState<any | null>(null); // State to store matching results

  const handleUploadComplete = (label: string, uploaded: boolean, fileName: string, file: File | null) => {
    if (label === 'resume') {
      setResumeUploaded(uploaded);
      setResumeFileName(fileName);
      setResumeFile(file);
    } else if (label === 'jd') {
      setJdUploaded(uploaded);
      setJdFileName(fileName);
      setJdFile(file);
    }
  };

  const handleSubmit = async () => {
    if (resumeUploaded && jdUploaded && resumeFile && jdFile) {
      setLoading(true); // Show loader when submission starts

      const formData = new FormData();
      formData.append('jd_file', jdFile);
      formData.append('cvs', resumeFile);

      try {
        const response = await axios.post('http://10.0.0.220:8003/process-cvs', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setLoading(false); // Hide loader
        setResults(response.data); // Store the results from the backend
        setUploadSuccess(true);
        setUploadError(false);
      } catch (error) {
        setLoading(false); // Hide loader on error
        setUploadError(true);
        setUploadSuccess(false);
      }
    }
  };

  // Function to calculate percentage for the progress bar
  const calculatePercentage = (score: number, maxScore: number = 10) => {
    return (score / maxScore) * 100;
  };

  // Render the results after processing
  const renderResults = () => {
    if (!results) return null;

    return (
      <div className="results-container p-6 bg-gray-50 rounded-lg shadow-md mt-6">
        <h2 className="text-3xl font-bold text-center mb-8">Resume-JD Matching Results</h2>

        {results.sorted_candidates.map((candidate: any, index: number) => (
          <div key={index} className="candidate-result p-6 bg-white rounded-lg shadow-md mb-8 flex gap-6 items-center">
            {/* Text details of candidate */}
            <div className="flex-grow">
              <h3 className="text-2xl font-bold mb-4">Candidate {index + 1}</h3>

              {/* Matching Skills */}
              <p className="text-lg">
                <strong>Matching Skills:</strong>{' '}
                <span className="text-green-600">{candidate.matched_skills_profile.matching_skills.join(', ')}</span>
              </p>

              {/* Missing Skills */}
              <p className="text-lg mt-2">
                <strong>Missing Skills:</strong>{' '}
                <span className="text-red-600">{candidate.matched_skills_profile.missing_skills.join(', ')}</span>
              </p>

              {/* Social Skills */}
              <p className="text-lg mt-2">
                <strong>Social Skills:</strong>{' '}
                {candidate.matched_skills_profile.social_skills[0].join(', ')}
              </p>

              {/* Score */}
              <p className="text-lg mt-4">
                <strong>Score:</strong> <span className="text-blue-600">{candidate.score}</span>
              </p>
            </div>

            {/* Circular progress bar for the score */}
            <div className="w-[120px]">
              <CircularProgressbar
                value={calculatePercentage(candidate.score, 10)}
                text={`${calculatePercentage(candidate.score, 10)}%`}
                styles={buildStyles({
                  textColor: '#000',
                  pathColor: '#b53f3b',
                  trailColor: '#d6d6d6',
                })}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col pb-40 bg-white max-md:pb-24">
      <main className="flex flex-wrap gap-8 self-center mt-10 ml-11 max-w-full w-[1304px] max-md:mt-10">
        {loading ? (
          <Loader /> // Show loader while processing
        ) : results ? (
          renderResults() // Show results after processing
        ) : (
          <>
            <section className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
              <div className="w-full max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col">
                  <div className="flex flex-col w-[39%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col items-start w-full font-semibold text-zinc-700 max-md:mt-10">
                      <img
                        loading="lazy"
                        src={resume_matching_5}
                        alt="Resume JD Matching illustration"
                        className="object-contain self-stretch w-full aspect-[1.5] rounded-[32px] border-4 border-black"
                      />
                      <h1 className="mt-5 text-6xl leading-[68px] max-md:mt-5 max-md:text-4xl max-md:leading-[50px]">
                        Resume JD Matching
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full">
                    <div className="mt-2 max-md:mt-10 max-md:max-w-full">
                      <div className="flex flex-col gap-8 max-md:flex-col">
                        {/* First upload box for the Resume */}
                        <div className="flex flex-col items-center">
                          <PDFUploader
                            label="resume"
                            onUploadComplete={(label, uploaded, fileName, file) =>
                              handleUploadComplete(label, uploaded, fileName, file)
                            }
                          />
                          <p className="mt-2 text-center text-lg font-semibold">Upload Resume</p>
                          {resumeFileName && <p className="mt-2 text-center text-sm text-gray-500">{resumeFileName}</p>}
                        </div>

                        {/* Second upload box for the Job Description */}
                        <div className="flex flex-col items-center">
                          <PDFUploader
                            label="jd"
                            onUploadComplete={(label, uploaded, fileName, file) =>
                              handleUploadComplete(label, uploaded, fileName, file)
                            }
                          />
                          <p className="mt-2 text-center text-lg font-semibold">Upload Job Description (JD)</p>
                          {jdFileName && <p className="mt-2 text-center text-sm text-gray-500">{jdFileName}</p>}
                        </div>

                        {/* Submit button */}
                        <div className="flex justify-center w-full mt-6">
                          <button
                            className={`px-6 py-3 bg-pink-600 text-white rounded-full ${
                              resumeUploaded && jdUploaded ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
                            }`}
                            disabled={!resumeUploaded || !jdUploaded}
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>

                        {uploadSuccess && (
                          <p className="mt-4 text-center text-green-600">Files uploaded successfully!</p>
                        )}

                        {uploadError && (
                          <p className="mt-4 text-center text-red-600">Error uploading files. Please try again.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
    </div>
  );
};

export default ResumeJDMatching;

