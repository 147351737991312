import React from 'react';
import Sidebar from './Sidebar';
import MainContent from './MainContent';

interface LayoutProps {
  children: React.ReactNode;
  stats: any[];
  meetings: any[];
  applications: any[];
  onSelectProfile: (profileId: number) => void;
  selectedProfileId: number | null; // Add this prop to Layout
}

const Layout: React.FC<LayoutProps> = ({
  children,
  stats,
  meetings,
  applications,
  onSelectProfile,
  selectedProfileId, // Make sure to include this prop
}) => {
  return (
    <div className="pr-20 bg-white max-md:pr-5">
      <div className="flex gap-5 max-md:flex-col">
        <Sidebar />
        <MainContent
          stats={stats}
          meetings={meetings}
          applications={applications}
          onSelectProfile={onSelectProfile}
          selectedProfileId={selectedProfileId} // Pass selectedProfileId to MainContent
        >
          {children}
        </MainContent>
      </div>
    </div>
  );
};

export default Layout;
