import React from 'react';

const HistoryPage: React.FC = () => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">History</h1>
      <p>This page displays your past activities or job posting history.</p>
      {/* Add history content */}
    </div>
  );
};

export default HistoryPage;
