import React from 'react';

interface SocialButtonProps {
  icon: string;
  text: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ icon, text }) => {
  return (
    <button className="flex items-center justify-center w-full px-4 py-2 mb-4 text-white bg-blue-500 rounded">
      <img src={icon} alt={text} className="w-6 h-6 mr-2" />
      {text}
    </button>
  );
};

export default SocialButton;
