import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface ProfileDropdownProps {
  onSelectProfile: (profileId: number) => void;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ onSelectProfile }) => {
  const [profiles, setProfiles] = useState<any[]>([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get('http://10.0.0.220:8009/profiles');
        setProfiles(response.data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <div className="flex flex-col">
      <label htmlFor="profile-select" className="gap-2 pl-1 w-full text-xs font-semibold leading-none text-zinc-950">
        Select Profile
      </label>
      <div className="flex gap-2.5 items-center px-4 mt-2 w-full text-base font-light rounded-xl border-2 border-gray-400 border-solid bg-slate-50 min-h-[56px] text-neutral-600">
        <select
          id="profile-select"
          className="flex-1 shrink self-stretch my-auto bg-transparent border-none outline-none appearance-none"
          onChange={(e) => onSelectProfile(Number(e.target.value))}
        >
          <option value="">Select Profile</option>
          {profiles.map((profile) => (
            <option key={profile.id} value={profile.id}>
              {profile.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ProfileDropdown;
