import React from 'react';

interface Meeting {
  date: string;
  time_start: string;
  time_end: string;
  title: string;
}

interface MeetingScheduleProps {
  meetings: Meeting[];
}

const MeetingSchedule: React.FC<MeetingScheduleProps> = ({ meetings }) => {
  return (
    <section className="flex flex-col bg-white p-6 rounded-3xl shadow-lg w-full max-w-md mt ml" style={{ height: '300px' }}>
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-neutral-900">Meetings</h2>
        <button className="flex gap-2 items-center text-neutral-400">
          <span className="text-xs">Create New</span>
          <span className="text-2xl">+</span>
        </button>
      </div>

      {/* Meetings List (Scrollable) */}
      <div className="overflow-y-auto" style={{ maxHeight: '330px' }}>
        {meetings.map((meeting, index) => (
          <div key={index} className="border-b border-gray-200 py-4">
            <div className="text-gray-600">
              {new Date(meeting.date).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </div>
            <div className="text-xl font-bold mt-2">{meeting.title}</div>
            <div className="text-sm text-gray-500 mt-1">
              {meeting.time_start} - {meeting.time_end}
            </div>
          </div>
        ))}

        {meetings.length === 0 && (
          <div className="text-gray-500 text-center mt-6">No meetings scheduled</div>
        )}
      </div>
    </section>
  );
};

export default MeetingSchedule;
