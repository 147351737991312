import React, { useState, useRef } from 'react';

type PDFUploaderProps = {
  label: string;
  onUploadComplete: (label: string, uploaded: boolean, fileName: string, file: File | null) => void;
};

const PDFUploader: React.FC<PDFUploaderProps> = ({ label, onUploadComplete }) => {
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setFileName(file.name);
      onUploadComplete(label, true, file.name, file);
    } else {
      alert('Please upload a PDF file.');
      onUploadComplete(label, false, '', null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setFileName(file.name);
      onUploadComplete(label, true, file.name, file);
    } else {
      alert('Please drop a PDF file.');
      onUploadComplete(label, false, '', null);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div
      className="flex flex-col items-center justify-center px-20 pt-24 pb-16 leading-none text-gray-600 bg-white rounded-[32px] shadow-[0px_0px_56px_rgba(69,69,69,0.2)] max-md:px-5 max-md:pt-20 max-md:mt-10 max-md:max-w-full"
      style={{ width: '70%', height: '270px' }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="flex flex-col items-center max-w-full w-[216px]">
        <label
          htmlFor={`pdfUpload-${label}`}
          className="self-stretch px-8 py-4 text-2xl text-center text-white bg-blue-600 rounded-full max-md:px-5 cursor-pointer"
        >
          Upload PDF
        </label>
        <input
          id={`pdfUpload-${label}`}
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          className="hidden"
          ref={fileInputRef}
        />
        <p className="mt-10 text-xl font-semibold leading-none">or drop a file,</p>
        <p className="flex gap-1 mt-0.5 max-w-full text-xs text-center w-[108px]">
          <span className="grow">paste PDF or</span>
          <span>URL</span>
        </p>
        {fileName && <p className="mt-4 text-sm">{fileName}</p>}
      </div>
    </div>
  );
};

export default PDFUploader;
